@import '../../../../styles/mixins.scss';

.event {
  @include flex(column, stretch, space-around);
  padding: 20px;

  @include xl-max {
    width: 100%;
  }

  @include sm-max {
    padding: 20px 10px;
  }

  .imgWrap {
    min-height: 300px;
    height: 300px;
    background-color: #f3f4f6;
    border-radius: 30px 30px 8px 8px;

    img {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      border-radius: 30px 8px 8px 8px;
      @include img;
      object-fit: cover;
      object-position: bottom bottom;
      height: 100%;

      @include xl-max {
        border-radius: 30px 30px 8px 8px;
      }
    }
  }

  .wrap {
    padding: 0 17px;
    min-height: 390px;
    background-color: var(--color-light-main);
    border-radius: 0 0 0 30px;
    margin: 0 0 17px 0;

    @include xl-max {
      border-radius: 0 0 30px 30px;
    }
  }
}

.icon {
  background-color: var(--color-dark);
  color: var(--color-light);
  @include flex(row, center, center);
  font-size: 2rem;
  transition: var(--transition);
  border-radius: 50%;

  width: 50px;
  height: 50px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-danger);
  }
}
