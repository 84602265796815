@import '../../../styles/mixins.scss';

.component {
  @include btn;
  background-color: var(--color-dark);
  color: var(--color-light);
  width: 100%;
  margin-top: 17px;

  &:hover {
    background: var(--color-danger);
  }
}
