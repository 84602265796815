@import '../../../../styles//mixins.scss';

.logout {
  @include flex(row, center, flex-start);
  gap: 10px;
  padding: 0 0 0 25px;
  height: 60px;
  width: 100%;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  @include lg-max {
    width: auto;
    padding: 0 10px;
  }

  &:hover {
    color: var(--color-main);
    background-color: lighten($color: #080808, $amount: 5);
  }

  svg {
    font-size: 1.2rem;
  }

  p {
    @include lg-max {
      display: none;
    }
  }
}
