@import '../../../styles/mixins.scss';

.login {
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
  min-height: calc(100vh - 60px);
  @include flex(row-reverse, center, space-between);

  @include lg-max {
    flex-direction: column-reverse;
  }

  @include sm-max {
    padding: 10px;
  }

  form {
    background-color: darken($color: #5955b3, $amount: 5%);
    padding: 90px 50px;
    border-radius: 20px;
    flex: 0.8;
    @include flex(column, center, center);

    @include lg-max {
      padding: 90px 30px;
      width: 80%;
    }

    @include md-max {
      width: 100%;
      padding: 0 20px;
    }

    @include mini {
      background-color: transparent;
      padding: 0;
    }

    div:first-child {
      margin-bottom: 40px;
    }

    div:nth-child(3) {
      margin: 20px 0 40px 0;
    }

    button {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .inputWrapper {
    position: relative;
    width: 100%;

    input {
      width: 100%;
      padding: 12px;
      border: 1px solid var(--color-dark-grey);
      border-radius: 4px;
      outline: none;
    }

    .eyeIcon {
      position: absolute;
      @include flex(row, center, center);
      top: 0;
      right: 0;
      height: 100%;
      width: 40px;
      font-size: 1.2rem;
      background-color: var(--color-dark);
      border-radius: 0 4px 4px 0;
    }

    .passwordIcon {
      cursor: pointer;
    }
  }

  .error {
    text-align: left;
    font-weight: bold;
    font-size: 0.9rem;
  }
}

.section {
  text-align: center;
}
