@import '../../../styles/mixins.scss';

@keyframes floatAnimation {
  0% {
    transform: translate(-50%, 0) rotate(30deg);
  }
  50% {
    transform: translate(-45%, -10%) rotate(30deg);
  }
  100% {
    transform: translate(-50%, 0) rotate(30deg);
  }
}

.imgWrap {
  position: relative;

  @include lg-max {
    display: none;
  }

  img {
    width: 580px;
    object-fit: s;

    @include md-max {
      width: 100%;
    }
  }

  .astronaut {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%) rotate(30deg);
    width: 40%;
    height: auto;
    animation: floatAnimation 5s ease-in-out infinite;
  }
}
