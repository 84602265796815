@import '../../../../styles/mixins.scss';

.component {
  @include btn;
  background-color: var(--color-danger);
  color: var(--color-light);
  width: 100%;
  border-radius: 8px;

  &:hover {
    background: var(--color-dark);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
