.noDataAlert {
  background-color: var(--color-dark);
  color: var(--color-light);

  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
}
