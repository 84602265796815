@import '../../../../styles//mixins.scss';

.recruiterSection {
  max-width: calc(210px * 5.6);
  margin: 0 auto;
  text-align: center;
}

.projectsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 30px;
  margin: 2rem 0;

  @include sm-max {
    @include flex(column, stretch, center);
  }

  .projectCard {
    background-color: var(--color-light);
    border-radius: 17px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: left;
  }

  .projectImage {
    width: 100%;
    height: 300px;
    cursor: pointer;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      object-fit: cover;
    }
  }

  .projectContent {
    padding: 1.5rem;
  }
}

.projectTitle {
  font-size: 1.4rem;
}

.projectLinks {
  margin-top: 1rem;
  @include flex(row, center, space-between);

  @include sm-max {
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
  }
}

.projectLink {
  background-color: var(--color-main);
  @include btn;
  color: white;
  font-size: 0.9rem;

  &:hover {
    background-color: var(--color-dark);
  }
}
