@import '../../../styles/mixins.scss';

.sectionComponent {
  background-color: var(--color-light);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: relative;
  @include flex(row, flex-start, space-between);
  min-height: calc(100vh - 34px);
  overflow: hidden;

  @include xl-max {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  @include sm-max {
    border-radius: 15px;
  }

  &:hover {
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2);
  }
}

.column {
  @include flex(column, flex-start, flex-start);
}
