@mixin p-el($element) {
  &:#{$element},
  &::#{$element} {
    position: absolute;
    @content;
  }
}

@mixin shadow {
  box-shadow: 20px 24px 25px 0px rgba(5, 5, 5, 0.4);
}

@mixin img {
  width: 100%;
  height: 100%;
}

@mixin btn {
  border: 0;
  text-align: center;
  line-height: 1.3;
  padding: 10px 20px;
  margin: 0;
  border-radius: var(--radius);
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  transition: var(--transition);
  height: auto;
}

@mixin flex($flex-direction, $align-items, $justify-content) {
  display: flex;
  flex-direction: $flex-direction;
  align-items: $align-items;
  justify-content: $justify-content;
}

// Mobile devices

@mixin mini {
  @media screen and (max-width: 244px) {
    @content;
  }
}

@mixin sm-max {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin sm-min {
  @media screen and (min-width: 480px) {
    @content;
  }
}

// Tablet devices
@mixin md-max {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin md-min {
  @media screen and (min-width: 768px) {
    @content;
  }
}

// Desctop devices
@mixin lg-max {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin lg-min {
  @media screen and (min-width: 992px) {
    @content;
  }
}

// Large-desctop devices
@mixin xl-max {
  @media screen and (max-width: 1111px) {
    @content;
  }
}

@mixin xl-min {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin dropdown {
  opacity: 0;
  height: 0;
  max-height: 0;
  overflow: hidden;
}
