@import '../../../styles/mixins.scss';

.component {
  @include flex(column, flex-start, flex-start);
  width: 100%;
  color: var(--color-light);
  padding: 20px 0;
  border-bottom: 1px solid var(--color-main);
  z-index: 99999;
  word-break: break-all;
  transition: max-height 200ms ease, padding 200ms ease, transform 200ms ease;

  @include lg-max {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--color-dark);
    border-bottom: none;
    @include shadow;
    overflow: hidden;

    &.open {
      max-height: 500px;
      opacity: 1;

      @include sm-max {
        height: 100vh;
        max-height: 100vh;
      }
    }

    &.close {
      height: 0;
      padding: 0;
      z-index: -9999;

      > * {
        height: 0;
        opacity: 0;
      }
    }
  }

  a {
    @include flex(row, center, flex-start);
    gap: 10px;
    padding: 0px 25px;
    width: 100%;
    height: 60px;
    color: var(--color-light);
    transition: 0.3s ease;

    &:hover {
      background-color: lighten($color: #080808, $amount: 5%);
      border-left: 5px solid var(--color-main);
    }

    &.active {
      background-color: lighten($color: #080808, $amount: 10%);
      border-bottom: none;
    }
  }

  .navLink {
    width: 100%;
    text-align: left;
  }

  .mark {
    @include flex(row, center, center);
    padding-bottom: 2px;
    font-size: 1.2rem;
  }

  span {
    font-size: 1.1rem;
    display: inline-block;
  }
}
