@import '../../../../styles/mixins.scss';

.componentWrap {
  width: 100%;
  padding: 0 17px;
  color: var(--color-main);
  margin-bottom: 20px;
}
.error {
  color: var(--color-error);
  padding: 17px 0 0 0;
}

.success {
  color: green;
}
