@import '../../../styles/mixins.scss';

.creator {
  padding: 1rem 1rem 1rem 1rem;

  input {
    width: 100%;
    background: var(--color-light);
    border: 1px solid lighten(black, 60%);
    padding: 1rem 1.5rem;
    border-radius: 12px;
  }
}

.buttons {
  @include flex(row, center, space-between);
  opacity: 0;
  height: 0;
  transition: all 200ms;
  margin-top: 1rem;

  &.buttonsShown {
    opacity: 1;
    height: 40px;
  }

  > * {
    flex-grow: 1;
    flex-basis: 0;

    &:first-child {
      margin: 0;

      ~ * {
        margin: 0 0 0 1rem;
      }
    }
  }
}
