@import '../../../styles/mixins.scss';

.column {
  flex-shrink: 0;
  width: calc(33% - 1.3rem);
  background: var(--color-grey);
  margin: 0 1rem 1rem 0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  color: var(--color-light);

  @include md-max {
    width: 100%;
  }

  &:last-child {
    margin: 0 0 1rem 0;
  }

  .title {
    @include flex(column, center, center);
    font-size: 1.4rem;
    padding: 1rem 1rem;
    background-color: var(--color-dark);
    border-radius: 12px 12px;
    @include flex(row, center, center);
    gap: 1rem;
    cursor: pointer;

    svg {
      font-size: 25px;
    }
  }

  .cardWrap {
    opacity: 0;
    height: 0;
    transition: all 200ms;
    overflow: hidden;

    &.buttonsShown {
      opacity: 1;
      height: auto;
    }
  }
}
