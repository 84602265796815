@import '../../../../styles/mixins.scss';

.cart {
  cursor: pointer;
  color: var(--color-main);
  z-index: 1;

  .cartImage {
    @include flex(row, center, space-between);
    flex-wrap: wrap;
    border-radius: 12px;
    padding: 20px;
    overflow: hidden;
    position: relative;

    &.completed {
      background-color: var(--color-dark-blue);
      color: var(--color-light);
    }

    &.ongoing {
      background-color: var(--color-blue);
      color: #434494;
    }

    &.planned {
      background-color: var(--color-blue-light);
      color: var(--color-dark-blue);
    }

    h2 {
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
}

.acordion {
  padding: 20px;
  border-radius: 12px;
  transition: max-height 0.25s ease-in-out, diaply ease-in-out 0s,
    transform 0.3s ease-in-out;
  background-color: var(--color-light-main);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  .acordionWrap {
    @include flex(column, stretch, space-between);
  }

  p {
    margin: 0;
  }

  .status {
    border-bottom: 1px solid darken($color: #f4f4fe, $amount: 8);
    padding: 15px 0;
  }

  .description,
  .price {
    padding: 15px 0;
    font-size: 0.9rem;
  }

  .buttonsWrap {
    @include flex(row, center, space-between);
    flex-wrap: wrap;
    border-top: 1px solid darken($color: #f4f4fe, $amount: 8);
    padding: 15px 0;

    div {
      @include flex(row, center, space-between);
      gap: 8px;
    }

    p {
      padding-left: 0;
    }
  }
}

.cartOpen {
  @include sm-max {
    height: auto;
  }
}

.cartClosed {
  display: none;
}
