@import '../../../styles//mixins.scss';

.spinerWraper {
  @include flex(column, center, center);
  gap: 10px;
  padding: 30px;
  border-radius: 30px;
  width: 100%;
  background-color: var(--color-light-main);
  color: var(--color-main);

  svg {
    font-size: 30px;
  }

  @include xl-max {
    width: 100%;
  }

  p {
    @include flex(row, center, center);
    gap: 10px;
  }
}
