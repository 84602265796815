@import '../../../styles/mixins.scss';

.creator {
  @include flex(row, center, center);
  // width: 33%;
  margin: 2rem auto 0;
}

.columns {
  @include flex(row, flex-start, stretch);
  overflow: auto;
  padding: 0 17px 17px 17px;
}

@include md-max {
  .columns {
    flex-direction: column;
    overflow: hidden;
  }

  .creator {
    width: 100%;
  }
}
