@import '../../../styles/mixins.scss';

.map {
  background-color: green;
  width: 100%;
  min-height: calc(100vh - 34px);
  height: calc(100vh - 34px);
  position: relative;

  @include xl-max {
    min-height: 40vh;
    height: 40vh;
  }
}

.title {
  font-size: 15px;
  font-family: var(--font-text);
  margin: 10px 10px;
  width: auto;
  color: var(--color-main);
  text-transform: uppercase;
  text-align: center;
}

button {
  z-index: 999;
}
