@import '../../../../styles/mixins.scss';

.buttonIcon,
.buttonNoUrl {
  color: var(--color-main);
  transition: var(--transition);
  @include btn;

  &:hover {
    color: var(--color-danger);
  }
}

.buttonNoUrl {
  background-color: transparent;
  border: none;
}
