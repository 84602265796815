@import './settings.scss';
@import './mixins.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--color-main);
  font-family: var(--font-text);
  font-size: (14px * 1.1);
  color: var(--color-light);
  letter-spacing: 0.5px;
  overflow: visible;
  line-height: 1.7;
  text-align: justify;
  white-space: normal;
}

.container {
  width: 80%;
  max-width: 1400px;
  margin: 0 auto;
  @include flex(column, center, center);

  @include sm-max {
    width: 95%;
  }
}

a,
a:hover,
a:visited {
  text-decoration: none;
}

li {
  list-style-type: none;
}

p {
  margin: 0;
}

@import './typography.scss';
