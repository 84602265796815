@import '../../../styles/mixins.scss';

.footer {
  padding: 20px 60px;
  @include flex(row, center, flex-end);
  color: var(--color-dark);
  gap: 20px;
  word-break: break-all;

  @include md-max {
    padding: 20px;
    flex-direction: column;
  }

  .links {
    @include flex(row, center, flex-end);
    gap: 15px;

    @include md-max {
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
    }

    a {
      color: var(--color-dark);
      font-size: 1.2rem;
      transition: color 0.3s;

      &:hover {
        color: var(--color-light);
      }
    }
  }
}
