@import '../../../styles/mixins.scss';

.userWrap {
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 999;
  @include flex(row, center, flex-center);
  gap: 20px;
  padding: 17px;
  transition: transform 0.3s ease-in-out;
  z-index: 1111;

  @include lg-max {
    top: 100px;
  }

  @include sm-max {
    top: 0px;
    right: 0;
    flex-direction: column-reverse;
  }

  .text {
    background-color: var(--color-light);
    padding: 20px 15px;
    border-radius: 20px;
    position: relative;
    transform-origin: center right;
    transition: all 0.5s ease-in-out;
    visibility: visible;
    transition: all 0.3s ease-in-out;
    color: var(--color-dark);
    @include flex(column, center, center);
    gap: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

    .ikonError,
    .ikonOk {
      font-size: 2rem;
      margin-bottom: 10px;
    }

    .ikonOk {
      color: var(--color-ok);
    }

    .close {
      position: absolute;
      top: 10px;
      right: 15px;
      font-size: 1.5rem;
      cursor: pointer;
    }

    &.expanded {
      visibility: visible;
      padding: 20px 15px;
    }

    &.collapsed {
      max-width: 60px;
      padding: 10px;
      text-align: center;
      visibility: visible;
      transform: translateX(0);
    }
  }

  .shortMessage {
    cursor: pointer;
  }

  .imgWrap {
    width: 75px;
    height: 75px;
    background-color: var(--color-light);
    padding: 8px;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
    @include flex(row, center, center);
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.6);

    @include sm-max {
      display: none;
    }

    img {
      @include img;
      object-fit: contain;
    }
  }
}
