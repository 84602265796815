@import '../../../styles/mixins.scss';

.welcome {
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
  min-height: calc(100vh - 60px);
  @include flex(row, center, space-between);

  @include lg-max {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 20px;
  }

  article {
    @include flex(column, flex-start, center);
    flex: 0.8;
    gap: 30px;
  }

  .btns {
    @include flex(row, center, center);
    width: 100%;
    gap: 20px;
    padding-top: 30px;

    @include sm-max {
      flex-direction: column;
      align-items: stretch;
    }

    a {
      flex: 1;
    }
  }
}
