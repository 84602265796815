@import '../../../styles/mixins.scss';

.logo {
  @include flex(row, center, flex-start);

  img {
    height: 100%;
    max-height: 100px;
    margin-right: 1rem;

    @include lg-max {
      max-height: 50px;
      margin-right: 0.5rem;
    }
    @include md-max {
      max-height: 100px;
      margin-right: 1rem;
    }

    @include sm-max {
      margin-right: 0;
      max-height: 40px;
    }

    @include mini {
      max-height: 80px;
    }
  }

  .textWrapper {
    @include flex(column, stretch, space-between);
    text-align: left;

    @include sm-max {
      text-align: center;
    }

    h1,
    p {
      margin: 0;
    }

    h1 {
      font-size: 50px;

      @include sm-max {
        display: none;
      }

      @include mini {
        font-size: 30px;
      }
    }

    p {
      font-size: 1rem;
      opacity: 0.8;

      @include mini {
        font-size: 0.8;
      }
    }
  }
}
