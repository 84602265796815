@import '../../../styles//mixins.scss';

.tasksList {
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  grid-gap: 17px;
  z-index: 1;
  padding: 0 17px 17px 17px;

  @include md-max {
    @include flex(column, stretch, center);
    padding: 0 15px 15px 15px;
  }
}

.noTasks {
  display: flex;
  flex-direction: column;
  padding: 0 17px 17px 17px;
}
