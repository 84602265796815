@import '../../../styles/mixins.scss';

.search {
  margin: 20px;
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 60px;
    width: calc(100% - 60px);
    flex-grow: 1;
    background: var(--colo-light);
    border: 1px solid lighten(black, 60%);
    font-family: var(--font-text);
    padding: 1rem 1.5rem;
    border-radius: 12px;
    transition: all 0.3s ease-in-out;
  }
}
