@import '../../../styles/mixins.scss';

.tag {
  background-color: var(--color-light-main);
  flex-wrap: wrap;
  color: var(--color-main);
  text-align: center;
  line-height: 1.3;
  border-radius: var(--radius);
  border: 1px solid var(--color-main);
  height: auto;
  @include flex(row, center, center);
  gap: 17px;
  width: auto;

  @include sm-max {
    width: 100%;
    justify-content: space-between;
  }

  .text {
    padding: 0.7rem 30px 0.7rem 0;
  }

  .wrap {
    background-color: var(--color-main);
    color: var(--color-light-main);
    display: inline-block;
    padding: 0.7rem;
    @include flex(column, center, center);

    svg {
      font-size: 20px;
    }
  }
}
