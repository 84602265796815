@import '../../../styles/mixins.scss';

.expand {
  @include flex(column, center, flex-start);
}

.chooseFileButton {
  @include btn;
  height: 50px;
  width: 100%;
}

input[type='file'],
textarea {
  width: 90%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 2px dashed #ccc;
  text-align: center;
  cursor: pointer;
  transition: var(--transition);

  &:hover {
    border-color: #5955b3;
  }
}

textarea {
  border: none;

  &:hover {
    border-color: none;
  }
}

.chooseFileButton {
  @include btn;
  background-color: var(--color-danger);
  color: var(--color-light);

  &:hover {
    background-color: darken(#28a745, 10%);
  }
}
