@import '../../../styles/mixins.scss';

.sectionHeader {
  text-transform: uppercase;
  width: 100%;
  padding: 17px;
  border-bottom: 1px var(--color-grey) solid;
  @include flex(row, center, space-between);

  @include md-max {
    text-align: center;
  }

  h2 {
    font-size: 17px;

    a {
      color: var(--color-light-main);
      transition: var(--transition);
      &:hover {
        color: var(--color-cv-green);
      }
    }
  }
}
