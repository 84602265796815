@import '../../../styles/mixins.scss';

.card {
  @include flex(row, center, space-between);
  background: var(--color-light);
  border: 1px solid var(--color-dark-grey);
  margin: 1rem;
  border-radius: 12px;

  .titleCard {
    color: var(--color-dark);
    padding: 1rem;
    font-size: 1rem;
  }

  span {
    color: var(--color-dark-blue);
    opacity: 0;
    cursor: pointer;
    font-weight: bold;
    transition: 0.2s ease-in-out;
    padding: 1rem;
  }
}

.card:hover {
  span {
    opacity: 1;
  }
}
