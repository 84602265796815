@import './settings.scss';
@import url('https://fonts.googleapis.com/css?family=Poppins:400,700');

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
