@import '../../../../styles/mixins.scss';

.eventsWrap {
  @include flex(row, center, center);
  width: 100%;
  flex-wrap: wrap;
  color: var(--color-main);
  gap: 20px;
  text-transform: uppercse;
  border-top: 1px var(--color-grey) solid;
  padding: 20px;

  @include xl-max {
    width: 100%;
  }

  @include sm-max {
    padding: 20px 10px;
  }
}
