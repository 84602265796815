@import '../../../../styles/mixins.scss';

.formLabelWrap {
  width: 100%;
  @include flex(column, flex-start, center);
  color: var(--color-main);

  label:first-letter {
    text-transform: uppercase;
  }
  label {
    margin: 10px 0;
    font-size: 0.95rem;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input {
    display: block;
    width: 100%;
    padding: 12px 16px;
    border: 1px solid var(--color-dark-grey);
    border-radius: 8px;
    background-color: var(--color-light);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    font-size: 1rem;

    &:focus {
      border-color: var(--color-primary);
      box-shadow: 0 0 6px rgba(0, 123, 255, 0.3);
      outline: none;
    }
  }
}

.error input {
  border-color: red;
}
