@import '../../../styles/mixins.scss';

.controlPanel {
  min-width: 33%;
  background-color: var(--color-grey);
  position: relative;
  transition: transform 0.3s ease-in-out;
  z-index: 988;
  overflow: hidden;
  min-height: calc(100vh - 34px);
  @include flex(column, center, center);

  @include xl-max {
    width: 100%;
    min-height: auto;
    @include flex(column, stretch, flex-start);
  }

  @include md-max {
    width: 100%;
  }

  @include xl-max {
  }
}
