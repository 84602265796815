@import '../../../styles/mixins.scss';

.paragraph {
  word-break: break-all;
  margin: 8px 0;
  @include flex(column, flex-start, center);

  strong {
    margin-right: 10px;
    color: var(--color-main);
  }
}
