@import '../../../styles/mixins.scss';

ul {
  padding: 0;
}

.technologies {
  @include flex(row, center, flex-start);
  gap: 10px;
  margin: 10px 0;

  svg {
    font-size: 1.2rem;
  }
}
