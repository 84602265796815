:root {
  /* Colors */
  --color-light: #fff;
  --color-dark: #080808;
  --color-dark-blue: #5e60ce;
  --color-blue: #a2a2fb;
  --color-blue-light: #cfcffb;
  --color-main: #5955b3;
  --color-purple: #5955b3;
  --color-grey: #eeedf1;
  --color-dark-grey: #ccc;
  --color-light-purple: #f7ecfc;
  --color-light-peach: #fbecf1;
  --color-light-main: #f4f4fe;
  --color-purple: #c37ee2;
  --color-peach: #a82450;
  --color-danger: #6a226b;
  --color-error: #dc3545;
  --color-darker-error: #c82333;
  --color-ok: #28a745;
  --color-yellow: #f5f071;
  --color-cv-green: #9eb8b7;
  --color-background: #f2f3f3;
  /* Fonts */
  --font-text: 'Poppins', sans-serif;

  --radius: 5px;
  --base-size: 12px;
  --transition: all 0.5s;

  /* Response */

  --screen-xxl: 1400px;
  --screen-xl: 1200px;
  --screen-l: 992px;
  --screen-md: 768px;
  --screen-sm: 576px;
}
