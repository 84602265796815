@import '../../../styles//mixins.scss';

.componentWrap {
  @include flex(column-reverse, center, center);
  flex-wrap: nowrap;
  background-color: var(--color-main);
  padding: 0 40px;
  position: absolute;

  width: 100%;
  height: 100%;

  @include xl-min {
    @include flex(row, center, center);
  }

  @include sm-max {
    width: 90%;
    padding: 10px;
  }

  .infoWrap {
    @include flex(column, center, center);
    gap: 20px;
    width: 50%;

    @include lg-max {
      width: 80%;
    }
  }

  .imgWrap {
    background-color: var(--color-main);
    width: 40%;
    overflow: hidden;
    padding: 20px;

    @include lg-max {
      width: 80%;
    }

    @include sm-max {
      width: 90%;
      padding: 10px;
    }
  }
}
