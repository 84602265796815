@import '../../../styles/mixins.scss';

.infoContent {
  @include flex(row-reverse, stretch, center);
  flex-wrap: wrap;
  padding: 17px 30px;
  height: 80vh;

  @include lg-max {
    height: auto;
    padding-bottom: 40px;
  }
}

.imageWrapper {
  width: 50%;
  border-radius: 0 20px 20px 0;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);

  @include lg-max {
    width: 100%;
    min-height: 35vh;
    height: 35vh;
  }

  @include sm-max {
    border-radius: 15px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.9;
    transition: transform 0.4s ease-in-out;
    transform: scale(1.05);

    &:hover {
      transform: scale(1.1);
      opacity: 1;
    }
  }
}

.info {
  width: 50%;
  min-height: 40vh;
  border-radius: 20px 0 0 20px;
  background-color: var(--color-light);
  @include flex(column, center, space-between);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);

  @include lg-max {
    border-radius: 30px;
    margin: 20px auto;
    width: 100%;
    min-height: auto;
    height: auto;
  }

  @include sm-max {
    width: 100%;
    padding: 10px;
    height: auto;
  }
}
