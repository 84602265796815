@import '../../../styles//mixins.scss';

.slideNav {
  @include flex(row, center, space-between);
  width: 100%;
  padding: 15px 20px;

  .navigationSlide {
    background: transparent;
    border: none;
    color: var(--color-grey-dark);
    font-size: 1.2rem;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: var(--color-main);
    }

    &:focus {
      outline: none;
      color: var(--color-main);
    }
  }

  @include sm-max {
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 0;
  }
}

.dots {
  @include flex(row, center, center);
  gap: 8px;

  @include sm-max {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 20px;
    gap: 20px;
  }
}

.dot,
.activeDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &.dot {
    background-color: rgb(95, 95, 95);
  }
}

.activeDot {
  width: 12px;
  height: 12px;
  background-color: var(--color-main);
  transform: scale(1.2);
}
