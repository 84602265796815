@import '../../../styles/mixins.scss';

.navigation {
  position: fixed;
  left: 0;
  width: 250px;
  height: 100vh;
  background-color: var(--color-dark);
  padding: 17px 0;
  transition: transform 0.3s ease-in-out;
  @include flex(column, center, flex-start);
  z-index: 9999;

  @include lg-max {
    top: 0;
    width: 100%;
    height: auto;
    padding: 10px 20px;
    @include flex(row, center, space-between);
    flex-wrap: wrap;
  }
}

.toggleButton {
  display: none;

  @include lg-max {
    display: block;
    border: none;
    background-color: var(--color-dark);
    color: var(--color-light);
    cursor: pointer;
    font-size: 1.5rem;
  }

  div {
    @include flex(row, center, center);
    gap: 20px;
  }
  p {
    font-size: 1rem;
  }
}

.cockpit {
  flex: 1;
  margin-left: 250px;
  width: calc(100vw - 267px);
  padding: 17px 17px 0 17px;
  color: var(--color-dark);

  @include lg-max {
    margin-left: 0;
    width: 100%;
    padding-top: 97px;
  }
}

.layoutWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .logoWrap {
    cursor: pointer;
    color: var(--color-light);

    img {
      height: 55px;
    }

    h1 {
      font-size: 25px;
    }
  }
}
