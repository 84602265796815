@import '../../../styles/mixins.scss';

.library {
  background-color: var(--color-light);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  min-height: calc(100vh - 34px);
  overflow: hidden;
  transition: all 200ms ease-in-out;

  @include flex(column, stretch, flex-start);
  gap: 17px;

  @include sm-max {
    border-radius: 15px;
  }

  &:hover {
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2);
  }
}
